const usePermissionConfig = () => {
  return {
    /*
    * 新增组织节点
    * 控制：系统设置-系统设置-用户管理-新增组织节点
    * 跳转路由名称：
    * */
    CREATE_ORG: 'MOKEN-XTSZ-XTSZ-YHGL-XZZZJD',
    /*
    * 编辑组织节点
    * 控制：系统设置-系统设置-用户管理-编辑组织节点
    * 跳转路由名称：
    * */
    UPDATE_ORG: 'MOKEN-XTSZ-XTSZ-YHGL-BJZZJD',
    /*
    * 删除组织节点
    * 控制：系统设置-系统设置-用户管理-删除组织节点
    * 跳转路由名称：
    * */
    DELETE_ORG: 'MOKEN-XTSZ-XTSZ-YHGL-SCZZJD',
    /*
    * 新增用户
    * 控制：系统设置-系统设置-用户管理-新增用户
    * 跳转路由名称：
    * */
    CREATE_USER: 'MOKEN-XTSZ-XTSZ-YHGL-XZYH',
    /*
    * 编辑用户
    * 控制：系统设置-系统设置-用户管理-编辑用户
    * 跳转路由名称：
    * */
    UPDATE_USER: 'MOKEN-XTSZ-XTSZ-YHGL-BJYH',
    /*
    * 用户禁用启用
    * 控制：系统设置-系统设置-用户管理-用户禁用启用
    * 跳转路由名称：
    * */
    UPDATE_USER_STATE: 'MOKEN-XTSZ-XTSZ-YHGL-YHJYQY',
    /*
    * 重置密码
    * 控制：系统设置-系统设置-用户管理-重置密码
    * 跳转路由名称：
    * */
    RESET_PASSWORD: 'MOKEN-XTSZ-XTSZ-YHGL-CZMM',
  };
};

export default usePermissionConfig;
