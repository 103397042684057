import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59b93b9a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "area-wrap" }
const _hoisted_2 = { class: "area-header" }
const _hoisted_3 = { class: "font-size-16" }
const _hoisted_4 = { class: "area-search" }
const _hoisted_5 = { class: "area-tree" }
const _hoisted_6 = { class: "tree-item" }
const _hoisted_7 = { class: "tree-item-content" }
const _hoisted_8 = { class: "tree-item-oper" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_tree = _resolveComponent("el-tree")!
  const _component_org_operate_dialog = _resolveComponent("org-operate-dialog")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_openBlock(), _createElementBlock("aside", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.companyName), 1)
    ]),
    _createElementVNode("section", _hoisted_4, [
      _createVNode(_component_el_input, {
        modelValue: _ctx.filterKeyword,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterKeyword) = $event)),
        placeholder: "请输入关键字"
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("main", _hoisted_5, [
      _createVNode(_component_el_scrollbar, { "max-height": "100%" }, {
        default: _withCtx(() => [
          _createElementVNode("section", null, [
            _createVNode(_component_el_tree, {
              ref: "treeRef",
              class: _normalizeClass({
              'tree-wrap': true
            }),
              data: _ctx.treeData,
              "highlight-current": true,
              "node-key": "orgId",
              "default-expanded-keys": _ctx.expandedKeys,
              props: {
              label: 'orgName',
              value: 'orgId',
              children: 'children',
            },
              "filter-node-method": _ctx.handleFilterNode,
              "expand-on-click-node": true,
              onNodeClick: _ctx.handleNodeClick,
              onNodeExpand: _ctx.handleNodeExpand,
              onNodeCollapse: _ctx.handleNodeCollapse
            }, {
              default: _withCtx(({ data, node }) => [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("span", null, _toDisplayString(data.orgName), 1)
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    (_ctx.$has(_ctx.permissionConfig.CREATE_ORG))
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          class: "tree-btn-text text-color-blue",
                          onClick: _withModifiers(($event: any) => (_ctx.handleAddChildNode(data, node)), ["stop"])
                        }, " 新增 ", 8, _hoisted_9))
                      : _createCommentVNode("", true),
                    (node.level !== 1 && _ctx.$has(_ctx.permissionConfig.UPDATE_ORG))
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 1,
                          class: "tree-btn-text text-color-blue",
                          onClick: _withModifiers(($event: any) => (_ctx.handleEditNode(data)), ["stop"])
                        }, " 编辑 ", 8, _hoisted_10))
                      : _createCommentVNode("", true),
                    (
                      _ctx.$has(_ctx.permissionConfig.DELETE_ORG)
                        && node.level !== 1
                        && !(data.systemParameterList && data.systemParameterList.length)
                        && data.children.length === 0
                    )
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 2,
                          class: "tree-btn-text text-color-red",
                          onClick: _withModifiers(($event: any) => (_ctx.handleDelete(data, node)), ["stop"])
                        }, " 删除 ", 8, _hoisted_11))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _: 1
            }, 8, ["data", "default-expanded-keys", "filter-node-method", "onNodeClick", "onNodeExpand", "onNodeCollapse"])
          ]),
          _createVNode(_component_org_operate_dialog, {
            ref: "orgOperateDialogInstance",
            visible: _ctx.orgOperateDialog.visible,
            "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.orgOperateDialog.visible) = $event)),
            orgData: _ctx.orgOperateDialog.orgData,
            operateType: _ctx.orgOperateDialog.operateType,
            onOnOkCallback: _ctx.handleNodeCallback
          }, null, 8, ["visible", "orgData", "operateType", "onOnOkCallback"])
        ]),
        _: 1
      })
    ])
  ]))
}