import { IColumnsItem } from '@/components/custom-table/package/type.d';
import {
  IOrgUserItem,
} from '@/modules/setting-manage/user-manage/api/type';
import {
  ORG_USER_STATUS_LIST,
  ORG_USER_STATUS_REVERT_LIST,
  SYSTEM_NAME_ALIAS_MAP,
} from '@/modules/setting-manage/user-manage/constant';
import { $filters } from '@/core/plugins/filter';
import { getOrgTreeKey } from '@/modules/setting-manage/user-manage/views/list/utils';

export const renderColumns = (
  handleEdit: (row: IOrgUserItem) => void,
  handleEnableChange: (row: IOrgUserItem) => void,
  handleResetPassword: (row: IOrgUserItem) => void,
  canUpdateUser: boolean,
  canUpdateState: boolean,
  canResetPassword: boolean,
): IColumnsItem<IOrgUserItem>[] => {
  return [
    {
      label: '序号',
      type: 'index',
      width: '55',
      align: 'center',
    },
    {
      label: '用户编码',
      minWidth: '120',
      prop: 'userCode',
      align: 'center',
    },
    {
      label: '用户名称',
      prop: 'username',
      minWidth: '200',
      align: 'center',
    },
    {
      label: '部门',
      minWidth: '150',
      align: 'center',
      render: (row: IOrgUserItem) => {
        return (
          <div>
            { getOrgTreeKey(row.orgList, 'orgName').map(it => it.join('-')).join('、') }
          </div>
        );
      },
    },
    {
      label: '角色',
      minWidth: '150',
      align: 'center',
      render: (row: IOrgUserItem) => {
        return (
          <div>
            {
              (row.systemRole || []).map((it) => {
                const systemName = SYSTEM_NAME_ALIAS_MAP[it.systemCode] || it.systemName;
                const roleListName = it.roles.map(roleItem => roleItem.roleName).join('、');
                return <div>{`${systemName}：${roleListName}`}</div>;
              })
            }
          </div>
        );
      },
    },
    {
      label: '手机号',
      minWidth: '140',
      prop: 'phone',
      align: 'center',
    },
    {
      label: '状态',
      width: '120',
      align: 'center',
      render: (row: IOrgUserItem) => {
        return (
          <div>
            {$filters.getEnumLabel(ORG_USER_STATUS_LIST, row.status)}
          </div>
        );
      },
    },
    {
      width: '150px',
      label: '操作',
      align: 'center',
      fixed: 'right',
      render: (row: IOrgUserItem) => {
        return (
          <div>
            {canUpdateUser && (
              <el-button type="text" onClick={() => handleEdit(row)}>
                编辑
              </el-button>
            )}
            <div>
              {canUpdateState && (
                <el-button type="text" onClick={() => handleEnableChange(row)}>
                  {$filters.getEnumLabel(ORG_USER_STATUS_REVERT_LIST, row.status)}
                </el-button>
              )}
              {canResetPassword && (
                <el-button type="text" onClick={() => handleResetPassword(row)}>
                  重置密码
                </el-button>
              )}
            </div>
          </div>
        );
      },
    },
  ];
};
