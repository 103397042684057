
import { defineComponent, watch, ref, computed } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import AsideTree from './components/aside-tree/index.vue';
import { useList } from '@/hooks/use-list';
import { renderColumns } from './components/table-list/tableData';
import OrgUserOperateDialog from './components/org-user-operate-dialog/index.vue';
import { IGetOrgUserListReq, IGetOrgTreeItem, IOrgUserItem } from '@/modules/setting-manage/user-manage/api/type';
import { changeUserEnabledStatus, getOrgUserList, resetUserPassword } from '@/modules/setting-manage/user-manage/api';
import { useOrgUserOperateDialog } from './components/org-user-operate-dialog/use-org-user-operate-dialog';
import { $filters, has } from '@/core/plugins/filter';
import {
  ORG_USER_STATUS_REVERT_LIST,
  ORG_USER_STATUS_LIST,
  STATUS_REVERT_MAP,
} from '@/modules/setting-manage/user-manage/constant';
import { SYSTEM_ENUM } from '@/constant/global';
import usePermissionConfig from '@/modules/setting-manage/user-manage/use-permission-config';

export default defineComponent({
  components: {
    AsideTree,
    OrgUserOperateDialog,
  },
  setup() {
    const activeOrgData = ref<IGetOrgTreeItem>({
      orgCode: '',
      orgId: '',
      orgName: '',
      parentCode: '',
      parentId: '',
    });

    const handleClickNodeChange = (data: IGetOrgTreeItem) => {
      activeOrgData.value = data;
    };
    const permissionConfig = usePermissionConfig();
    const requestParams: IGetOrgUserListReq = {
      // orgId: undefined,
      status: '',
      selectSystemCode: [SYSTEM_ENUM.MOKEN],
      userCode: '',
      username: '',
      currentPage: 1,
      pageSize: 10,
    };
    const {
      params,
      tableTotal,
      tableData,
      tableLoading,
      handleSearch,
      handleReset,
      handleSizeChange,
      handleCurrentChange,
    } = useList<IOrgUserItem, IGetOrgUserListReq>({
      request: {
        api: getOrgUserList,
        params: requestParams,
        pageNumKey: 'currentPage',
      },
      response: {
        tableDataKey: 'data.pageData',
        totalKey: 'data.totalCount',
      },
    });

    const {
      orgUserOperateDialog,
      handleAddOrgUser,
      handleEditOrgUser,
    } = useOrgUserOperateDialog();
    const dispatchDepConfig = ref({
      visible: false,
      id: '',
      name: '',
    });

    // 重置密码
    const handleResetPassword = async (row: IOrgUserItem) => {
      try {
        await ElMessageBox.confirm(`确认重置用户【${row.username}】的密码`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });
        await resetUserPassword({
          id: row.userId,
        });
        ElMessage.success('重置密码成功');
        handleSearch(params.value.currentPage);
      } catch (e) {
        console.log(e);
      }
    };
    const handleEnableChange = async (row: IOrgUserItem) => {
      try {
        const label = $filters.getEnumLabel(ORG_USER_STATUS_REVERT_LIST, row.status);
        await ElMessageBox.confirm(
          `确认${label}【${row.username}】用户`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          },
        );
        await changeUserEnabledStatus({
          userCode: row.userCode,
          userId: row.userId,
          status: STATUS_REVERT_MAP[row.status],
        });
        ElMessage.success(`用户：【${row.username}】${label}成功`);
        handleSearch(params.value.currentPage);
      } catch (e) {
        console.log(e);
      }
    };
    watch(() => activeOrgData.value?.orgId, (orgId: string) => {
      if (!activeOrgData.value?.orgId) return;
      requestParams.orgId = orgId;
      params.value.orgId = orgId;
      handleSearch();
    });
    const canUpdateUser = computed(() => has(permissionConfig.UPDATE_USER));
    const canUpdateState = computed(() => has(permissionConfig.UPDATE_USER_STATE));
    const canResetPassword = computed(() => has(permissionConfig.RESET_PASSWORD));
    return {
      canUpdateUser,
      canUpdateState,
      canResetPassword,
      permissionConfig,
      dispatchDepConfig,
      params,
      tableTotal,
      tableData,
      tableLoading,
      handleSearch,
      handleReset,
      handleSizeChange,
      handleCurrentChange,
      renderColumns,
      handleResetPassword,
      handleEnableChange,

      orgUserOperateDialog,
      handleAddOrgUser,
      handleEditOrgUser,

      ORG_USER_STATUS_LIST,
      activeOrgData,
      handleClickNodeChange,
    };
  },
});
