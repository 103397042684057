import { SYSTEM_ENUM } from '@/constant/global';
// 组织用户状态
export enum ORG_USER_STATUS_ENUM {
  /*
  * 禁用
  * */
  DISABLE = '0',
  /*
  * 启用
  * */
  ENABLE = '1',
}
export const ORG_USER_STATUS_LIST = [
  { value: ORG_USER_STATUS_ENUM.ENABLE, label: '启用' },
  { value: ORG_USER_STATUS_ENUM.DISABLE, label: '停用' },
];

export const ORG_USER_STATUS_REVERT_LIST = [
  { value: ORG_USER_STATUS_ENUM.ENABLE, label: '停用' },
  { value: ORG_USER_STATUS_ENUM.DISABLE, label: '启用' },
];

// 状态转换，用于启停用切换
export const STATUS_REVERT_MAP = {
  [ORG_USER_STATUS_ENUM.DISABLE]: ORG_USER_STATUS_ENUM.ENABLE,
  [ORG_USER_STATUS_ENUM.ENABLE]: ORG_USER_STATUS_ENUM.DISABLE,
};

export type SYSTEM_NAME_ALIAS_MAP_TYPE = Record<SYSTEM_ENUM, string>;

export const SYSTEM_NAME_ALIAS_MAP: SYSTEM_NAME_ALIAS_MAP_TYPE = {
  [SYSTEM_ENUM.MOKEN]: '角色',
};
