import { IOrgTreeItem } from '../aside-tree/type';
/**
 * 部门树节点操作行为
 */

export enum ORG_DIALOG_OPERATE_ENUM {
  /* 新增 */
  ADD = 'ADD',
  /* 编辑 */
  MODIFY = 'MODIFY',
  /* 删除 */
  DELETE = 'DELETE'
}

export const ORG_DIALOG_LIST = [
  { value: ORG_DIALOG_OPERATE_ENUM.ADD, label: '新增' },
  { value: ORG_DIALOG_OPERATE_ENUM.MODIFY, label: '编辑' },
];

// 弹窗组件参数
export interface IOrgOperateDialog {
  visible: boolean;
  operateType: ORG_DIALOG_OPERATE_ENUM;
  // 节点数据 新增 为父节点数据、新增根节点为null，编辑为当前节点数据
  orgData: IOrgTreeItem | null;
}
