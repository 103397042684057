import http from '@/core/http';
import {
  IAddOrgReq,
  IAddUserReq,
  IDeleteOrgReq,
  IGetOrgUserListReq,
  IGetOrgUserListRes,
  IGetOrgTreeItem,
  IModifyOrgReq,
  IModifyUserReq,
  IResetUserPasswordReq,
  IGetRoleTreeBySystemCodeReq,
  IGetRoleTreeBySystemCodeItemRes,
  IAddOrgRes,
  IChangeUserEnabledStatusReq,
  IGetCurrentOrgUsersRes,
  IGetCurrentOrgUsersReq,
} from './type';
import { DOMAIN_SYSTEM_ENUM } from 'cx-utils';

// 获取组织树
export const getOrgTree = () => {
  return http.post<IGetOrgTreeItem[]>({
    server: DOMAIN_SYSTEM_ENUM.tiangong,
    url: '/sso-center/admin/orgs/list',
    loading: true,
    config: {
      withCredentials: false,
    },
  });
};
// 新增组织
export const addOrg = (data: IAddOrgReq) => {
  return http.post<IAddOrgRes>({
    server: DOMAIN_SYSTEM_ENUM.tiangong,
    url: '/sso-center/admin/orgs/add',
    data,
    loading: true,
    config: {
      withCredentials: false,
    },
  });
};
// 删除组织
export const deleteOrg = (data: IDeleteOrgReq) => {
  return http.post({
    server: DOMAIN_SYSTEM_ENUM.tiangong,
    url: '/sso-center/admin/orgs/delete',
    data,
    loading: true,
    config: {
      withCredentials: false,
    },
  });
};

// 编辑组织
export const modifyOrg = (data: IModifyOrgReq) => {
  return http.post({
    server: DOMAIN_SYSTEM_ENUM.tiangong,
    url: '/sso-center/admin/orgs/modify',
    data,
    loading: true,
    config: {
      withCredentials: false,
    },
  });
};

// 获取组织用户列表
export const getOrgUserList = (data: IGetOrgUserListReq) => {
  return http.post<IGetOrgUserListRes>({
    server: DOMAIN_SYSTEM_ENUM.tiangong,
    url: '/moken-portal/web/v1/tenant-person/page',
    data,
    loading: true,
    // config: {
    //   withCredentials: false,
    // },
  });
};

// 添加组织下的用户
export const addOrgUser = (data: IAddUserReq) => {
  return http.post({
    server: DOMAIN_SYSTEM_ENUM.tiangong,
    url: '/moken-portal/web/v1/tenant-person/save',
    data,
    loading: true,
  });
};

// 编辑组织下的用户
export const modifyOrgUser = (data: IModifyUserReq) => {
  return http.post({
    server: DOMAIN_SYSTEM_ENUM.tiangong,
    url: '/moken-portal/web/v1/tenant-person/update',
    data,
    loading: true,
  });
};

// 重置用户密码
export const resetUserPassword = (data: IResetUserPasswordReq) => {
  return http.post({
    server: DOMAIN_SYSTEM_ENUM.tiangong,
    url: '/sso-center/admin/users/pwd/reset',
    data,
    loading: true,
    config: {
      withCredentials: false,
    },
  });
};

// 用户状态切换
export const changeUserEnabledStatus = (data: IChangeUserEnabledStatusReq) => {
  return http.post({
    server: DOMAIN_SYSTEM_ENUM.tiangong,
    url: '/moken-portal/web/v1/tenant-person/status-update',
    data,
    loading: true,
  });
};

// 根据系统编码查询角色树
export const getRoleTreeBySystemCode = (data: IGetRoleTreeBySystemCodeReq) => {
  return http.post<IGetRoleTreeBySystemCodeItemRes[]>({
    server: DOMAIN_SYSTEM_ENUM.tiangong,
    url: '/sso-center/roles/trees',
    data,
    loading: true,
    config: {
      withCredentials: false,
    },
  });
};

// 查询租户下用户
export const getCurrentOrgUsers = (data: IGetCurrentOrgUsersReq) => {
  return http.post<IGetCurrentOrgUsersRes[]>({
    server: DOMAIN_SYSTEM_ENUM.tiangong,
    url: '/moken-portal/web/v1/tenant-person/search',
    data,
    loading: true,
  });
};
