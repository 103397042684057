import { ref } from 'vue';
import { getRoleTreeBySystemCode } from '@/modules/setting-manage/user-manage/api';
import { IGetRoleTreeBySystemCodeItemRes } from '@/modules/setting-manage/user-manage/api/type';
import { SYSTEM_NAME_ALIAS_MAP } from '@/modules/setting-manage/user-manage/constant';
import { SYSTEM_ENUM } from '@/constant/global';

interface IRoleTree extends IGetRoleTreeBySystemCodeItemRes {
  // 别名（用于展示 label）
  systemNameAlias: string;
}

// 使用角色tree
const useRoleTree = (systemCodeList: SYSTEM_ENUM[]) => {
  const roleTree = ref<IRoleTree[]>([]);
  const getRoleTree = async () => {
    try {
      const { data } = await getRoleTreeBySystemCode({
        selectSystemCodes: systemCodeList,
      });
      roleTree.value = (data || []).map((it) => {
        const item = {
          systemNameAlias: '',
          ...it,
        };
        item.systemNameAlias = SYSTEM_NAME_ALIAS_MAP[it.systemCode] || it.systemName;
        return item;
      });
    } catch (e) {
      console.log(e);
    }
  };
  const initRoleTree = async (isRefresh = false) => {
    if (roleTree.value.length && !isRefresh) {
      return;
    }
    getRoleTree();
  };
  return {
    roleTree,
    initRoleTree,
  };
};

export default useRoleTree;
