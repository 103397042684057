import { IGetOrgTreeItem } from '@/modules/setting-manage/user-manage/api/type';
import { getOrgTreeKey } from '@/modules/setting-manage/user-manage/views/list/utils';

export const useOrgTreePath = () => {
  let treePathList: string[][] = [];
  let orgTreeStr: string = '';

  const getOrgTreePath = (orgTree: IGetOrgTreeItem[]) => {
    const newOrgTreeStr = JSON.stringify(orgTree);
    if (orgTreeStr === newOrgTreeStr) {
      return treePathList;
    }
    treePathList = getOrgTreeKey(orgTree, 'orgName') as string[][];
    orgTreeStr = newOrgTreeStr;
    return treePathList;
  };

  return {
    getOrgTreePath,
  };
};
