import {
  IGetOrgTreeItem,
  IOrgUserItem,
} from '@/modules/setting-manage/user-manage/api/type';

/**
 * 弹窗操作类型
 */
export enum ORG_USER_DIALOG_OPERATE_ENUM {
  /* 新增 */
  ADD = 'ADD',
  /* 编辑 */
  MODIFY = 'MODIFY'
}
export const ORG_USER_DIALOG_OPERATE_LIST = [
  { value: ORG_USER_DIALOG_OPERATE_ENUM.ADD, label: '新建用户' },
  { value: ORG_USER_DIALOG_OPERATE_ENUM.MODIFY, label: '编辑用户' },
];

export interface IOrgUserOperateDialog {
  visible: boolean;
  /*
  * 操作类型
  * */
  operateType: ORG_USER_DIALOG_OPERATE_ENUM;
  /*
  * 组织数据
  * */
  orgData?: IGetOrgTreeItem | null;
  /*
  * 用户数据(编辑)
  * */
  userData?: IOrgUserItem | null;
}

export interface IOrgUserOperateForm {
  /*
  * 编辑选中的组织
  * */
  orgIdList: string[];
  /*
  * 编辑选中的角色
  * */
  roleIdList: string[];
  /*
  * 用户名称
  * */
  username: string;
  /*
  * 手机号码
  * */
  phone: string;
}

export interface IFlatOrgItem extends IGetOrgTreeItem {
  orgPath: string[];
}
