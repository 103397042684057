
import { defineComponent, PropType, ref } from 'vue';
import { IGetOrgTreeItem, IOrgUserItem } from '@/modules/setting-manage/user-manage/api/type';
import { ElForm, ElMessage } from 'element-plus';
import { validatefuns } from 'cx-utils';
import {
  IOrgUserOperateForm,
  ORG_USER_DIALOG_OPERATE_ENUM,
  ORG_USER_DIALOG_OPERATE_LIST,
  IOrgUserOperateDialog,
  IFlatOrgItem,
} from './type';
import useRoleTree from './use-role-tree';
import { addOrgUser, getOrgTree, modifyOrgUser } from '@/modules/setting-manage/user-manage/api';
import { getOrgTreeKey, recursiveDeleteKey } from '@/modules/setting-manage/user-manage/views/list/utils';
import { SYSTEM_ENUM } from '@/constant/global';
import { uniq } from 'lodash-es';

export default defineComponent({
  emits: ['update:visible', 'success'],
  props: {
    visible: {
      type: Boolean,
    },
    // 新增、编辑
    operateType: {
      type: String as PropType<ORG_USER_DIALOG_OPERATE_ENUM>,
      default: ORG_USER_DIALOG_OPERATE_ENUM.ADD,
    },
    /*
    * 用户数据
    * */
    userData: {
      type: Object as PropType<IOrgUserItem | null>,
    },
    // 部门数据
    orgData: {
      type: Object as PropType<IGetOrgTreeItem | null>,
    },
  },
  setup(props: IOrgUserOperateDialog, { emit }) {
    const {
      roleTree,
      initRoleTree,
    } = useRoleTree([SYSTEM_ENUM.MOKEN]);
    const formRef = ref<InstanceType<typeof ElForm> | null>(null);
    const form = ref<IOrgUserOperateForm>({
      username: '',
      phone: '',
      orgIdList: [],
      roleIdList: [],
    });
    const formRules = {
      username: [
        { required: true, message: '请输入用户名称' },
      ],
      phone: [
        {
          required: true,
          validator: (rule: any, value: string, callback: any) => {
            if (!validatefuns.isMobilephone(value)) {
              callback(new Error('手机格式错误'));
            } else {
              callback();
            }
          },
          trigger: 'blur',
        },
      ],
      orgIdList: [
        { required: true, message: '请选择部门' },
      ],
    };

    // 获取组织树
    const orgTree = ref<IGetOrgTreeItem[]>([]);
    const flatOrgTree = ref<IFlatOrgItem[]>([]);
    const getOrgTreeList = async () => {
      try {
        // 获取部门树
        const { data = [] } = await getOrgTree();
        // 递归删除 空 children
        recursiveDeleteKey(data);
        const deep = (
          reqArr: IGetOrgTreeItem[],
          parentPath: string[],
          flatArr: IFlatOrgItem[],
        ) => {
          reqArr.forEach((v) => {
            const path = [...parentPath, v.orgId];
            flatArr.push({
              ...v,
              orgPath: path,
            });
            if (v.children?.length) {
              deep(v.children, path, flatArr);
            }
          });
        };
        const flat:IFlatOrgItem[] = [];
        deep(data, [], flat);

        flatOrgTree.value = flat;
        orgTree.value = data;
      } catch (e) {
        console.log(e);
      }
    };
    const findOrgPath = (orgId: string) => {
      return flatOrgTree.value.find(v => v.orgPath[v.orgPath.length - 1] === orgId)?.orgPath || [];
    };
    const handleClose = async () => {
      try {
        // 初始化表单
        await formRef.value!.resetFields();
        emit('update:visible', false);
      } catch (e) {
        console.log(e);
      }
    };
    const handleConfirm = async () => {
      try {
        await formRef.value?.validate();
        if (props.operateType === ORG_USER_DIALOG_OPERATE_ENUM.ADD) {
          await addOrgUser({
            email: '',
            phone: form.value.phone,
            username: form.value.username,
            roles: form.value.roleIdList,
            orgs: [form.value.orgIdList.flat().pop()!],
          });
        } else {
          await modifyOrgUser({
            childOrgIds: [form.value.orgIdList[form.value.orgIdList.length - 1]],
            childRoleIds: form.value.roleIdList,
            orgRootList: orgTree.value.map(it => it.orgId),
            systemCodeList: [SYSTEM_ENUM.MOKEN],
            userCode: props.userData!.userCode,
            userId: props.userData!.userId,
            username: form.value.username,
          });
        }

        ElMessage.success('操作成功');
        handleClose();
        emit('success');
      } catch (e) {
        console.log(e);
      }
    };

    const handleOpen = async () => {
      // 初始化角色树
      initRoleTree();
      await getOrgTreeList();
      if (props.operateType === ORG_USER_DIALOG_OPERATE_ENUM.ADD) {
        form.value.username = '';
        form.value.phone = '';
        form.value.orgIdList = findOrgPath(props.orgData!.orgId);
        form.value.roleIdList = [];
      } else {
        // 编辑ID
        form.value.username = props.userData!.username;
        form.value.phone = props.userData!.phone;
        form.value.orgIdList = getOrgTreeKey(props.userData!.orgList || [], 'orgId')?.[0];
        form.value.roleIdList = props.userData!.systemRole.reduce((res, item) => {
          res.push(...item.roles.map(it => it.roleId));
          return res;
        }, [] as string[]);
      }
    };
    // 监听角色改变
    const handleChangeRole = (
      row: typeof roleTree.value[0]['allRoles'][0],
      roles: typeof roleTree.value[0]['allRoles'],
      systemCode: SYSTEM_ENUM,
    ) => {
      let list = [...form.value.roleIdList];
      const index = form.value.roleIdList.findIndex(v => v === row.roleId);
      if (systemCode === SYSTEM_ENUM.MOKEN) {
        if (index === -1) {
          list.push(row.roleId);
        } else {
          list.splice(index, 1);
        }
      } else if (index === -1) {
        list = list.filter(v => !roles.find(item => item.roleId === v));
        list.push(row.roleId);
      } else {
        list.splice(index, 1);
      }
      form.value.roleIdList = list;
    };
    return {
      roleTree,
      formRef,
      form,
      formRules,
      orgTree,
      handleChangeRole,
      handleConfirm,
      handleOpen,
      handleClose,
      ORG_USER_DIALOG_OPERATE_ENUM,
      ORG_USER_DIALOG_OPERATE_LIST,
    };
  },
});
