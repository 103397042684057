import { ref, Ref, nextTick, reactive } from 'vue';
import NodeDialog from './index.vue';
import { ITreeNode, IOrgTreeItem } from '../aside-tree/type';
import { ORG_DIALOG_OPERATE_ENUM, IOrgOperateDialog } from './type';

/**
 * 节点新建/编辑
 * @returns
 */
export default function useOrgOperateDialog(
  treeData: Ref<IOrgTreeItem[]>,
  handleScrollToPosition: (orgId: string) => void,
  nodeUpdater: (
    type: ORG_DIALOG_OPERATE_ENUM,
    parent: IOrgTreeItem|null,
    current: IOrgTreeItem,
    oldOrgName: string,
  ) => void,
) {
  // 组件实例
  const orgOperateDialogInstance = ref<typeof NodeDialog | null>(null);
  const orgOperateDialog = reactive<IOrgOperateDialog>(
    {
      visible: false,
      operateType: ORG_DIALOG_OPERATE_ENUM.ADD, // 操作类型
      orgData: null,
    },
  );
  // 父树节点
  const parentOrgNode = ref<ITreeNode | null>();

  // 添加根节点
  const handleAddRootNode = () => {
    orgOperateDialog.visible = true;
    orgOperateDialog.orgData = null;
    orgOperateDialog.operateType = ORG_DIALOG_OPERATE_ENUM.ADD;
  };

  // 添加子节点
  const handleAddChildNode = async (data: IOrgTreeItem, node: ITreeNode) => {
    orgOperateDialog.visible = true;
    orgOperateDialog.orgData = data;
    parentOrgNode.value = node;
    orgOperateDialog.operateType = ORG_DIALOG_OPERATE_ENUM.ADD;
  };

  // 编辑节点
  const handleEditNode = (data: IOrgTreeItem) => {
    orgOperateDialog.visible = true;
    orgOperateDialog.orgData = data;
    orgOperateDialog.operateType = ORG_DIALOG_OPERATE_ENUM.MODIFY;
  };
  // 编辑节点回调用，处理 展开 & 滚动
  const handleNodeCallback = async (data: IOrgTreeItem, oldOrgName:string = '') => {
    if (orgOperateDialog.operateType === ORG_DIALOG_OPERATE_ENUM.ADD) {
      // 新增子节点
      if (orgOperateDialog.orgData) {
        // 展开父节点
        if (parentOrgNode.value) {
          parentOrgNode.value.expanded = true;
        }
        const children = orgOperateDialog.orgData.children || [];
        children.push(data);
        orgOperateDialog.orgData.children = children;
      } else {
        // 添加根节点
        treeData.value.push(data);
      }
      // 滚动到指定位置。
      await nextTick();
      handleScrollToPosition && handleScrollToPosition(data.orgId);
    } else if (orgOperateDialog.operateType === ORG_DIALOG_OPERATE_ENUM.MODIFY && orgOperateDialog.orgData) {
      // 编辑
      orgOperateDialog.orgData.orgName = data.orgName;
    }
    nodeUpdater(orgOperateDialog.operateType, data.parent, data, oldOrgName);
  };
  return {
    orgOperateDialog,

    orgOperateDialogInstance,
    handleAddRootNode,
    handleAddChildNode,
    handleEditNode,
    handleNodeCallback,
  };
}
