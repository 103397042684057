import { ORG_USER_DIALOG_OPERATE_ENUM, IOrgUserOperateDialog } from './type';
import { IGetOrgTreeItem, IOrgUserItem } from '@/modules/setting-manage/user-manage/api/type';
import { reactive } from 'vue';

export const useOrgUserOperateDialog = () => {
  const orgUserOperateDialog = reactive<IOrgUserOperateDialog>({
    visible: false,
    operateType: ORG_USER_DIALOG_OPERATE_ENUM.ADD,
    orgData: null,
    userData: null,
  });

  const handleAddOrgUser = (orgData: IGetOrgTreeItem | null) => {
    orgUserOperateDialog.orgData = orgData;
    orgUserOperateDialog.visible = true;
    orgUserOperateDialog.operateType = ORG_USER_DIALOG_OPERATE_ENUM.ADD;
  };
  const handleEditOrgUser = (row: IOrgUserItem, orgData: IGetOrgTreeItem | null) => {
    orgUserOperateDialog.orgData = orgData;
    orgUserOperateDialog.userData = row;
    orgUserOperateDialog.operateType = ORG_USER_DIALOG_OPERATE_ENUM.MODIFY;
    orgUserOperateDialog.visible = true;
  };
  return {
    orgUserOperateDialog,
    handleAddOrgUser,
    handleEditOrgUser,
  };
};
