import {
  IGetOrgTreeItem,
} from '@/modules/setting-manage/user-manage/api/type';

interface IOrgItem {
  orgName: string;
  orgId: string;
  children?: IOrgItem[];
}

function recursive<T extends IOrgItem>(
  orgTree: T[],
  keyList: string[][],
  parentKeyList: string[],
  key: 'orgName' | 'orgId',
) {
  orgTree.forEach((it) => {
    if (it.children && it.children.length) {
      recursive(it.children, keyList, [...parentKeyList, it[key]], key);
    } else {
      keyList.push([...parentKeyList, it[key]]);
    }
  });
  return keyList;
}

/*
* orgTree 用户列表中的 组织树
* key: orgName、orgId
* 返回值：orgName、orgId -> string[][]
* */
export function getOrgTreeKey <T extends IOrgItem>(orgTree: (T)[], key: 'orgName' | 'orgId') {
  const keyList: string[][] = [];
  recursive(orgTree, keyList, [], key);
  return keyList;
}

/*
* 递归删除指定key
* */
export const recursiveDeleteKey = (tree: IGetOrgTreeItem[]) => {
  tree.forEach((it) => {
    if (it.children && it.children.length) {
      recursiveDeleteKey(it.children);
    } else {
      delete it.children;
    }
  });
};
