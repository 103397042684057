import { onMounted, ref, watch, getCurrentInstance, nextTick } from 'vue';
import { deleteOrg, getOrgTree } from '@/modules/setting-manage/user-manage/api';
import { IGetOrgTreeItem } from '@/modules/setting-manage/user-manage/api/type';
import { ElMessage, ElMessageBox, ElTree } from 'element-plus';
import {
  ITreeNode,
  IOrgTreeItem,
} from '@/modules/setting-manage/user-manage/views/list/components/aside-tree/type';
import {
  useOrgTreePath,
} from '@/modules/setting-manage/user-manage/views/list/components/aside-tree/use-org-tree-path';
import { ORG_DIALOG_OPERATE_ENUM } from '../org-operate-dialog/type';

export function useAsideTree() {
  const ctx = getCurrentInstance()!;
  const { emit } = ctx;

  const treeData = ref<IOrgTreeItem[]>([]);
  const activeNodeData = ref<IOrgTreeItem>();
  const nodeChildPathMap = new Map<string, string[]>();
  /*
  * tree展开的ID
  * */
  const expandedKeys = ref<string[]>([]);

  const treeRef = ref<typeof ElTree | null>(null);
  // 点击节点
  const handleNodeClick = (data: IOrgTreeItem) => {
    activeNodeData.value = data;
    emit('click-node-change', data);
  };
  // 获取部门数据
  const getData = async () => {
    const dfs = (
      resArr: IGetOrgTreeItem[],
      treeArr: IOrgTreeItem[],
      parentChildOrgPath: string[],
      parent: IOrgTreeItem|null,
    ) => {
      resArr.forEach((item: IGetOrgTreeItem) => {
        const children : IOrgTreeItem[] = [];
        const childrenOrgPath = [item.orgName];
        const nodeData: IOrgTreeItem = {
          ...item,
          children,
          parent,
        };
        nodeChildPathMap.set(item.orgId, childrenOrgPath);
        // expandedKeys.value.push(nodeData.orgId);
        treeArr.push(nodeData);
        if (item.children && item.children.length) {
          dfs(
            item.children,
            nodeData.children || [],
            childrenOrgPath,
            nodeData,
          );
        }
        parentChildOrgPath.push(...childrenOrgPath);
      });
    };
    const { data } = await getOrgTree();
    const trees: IOrgTreeItem[] = [];
    // 创建新数据
    dfs(data || [], trees, [], null);
    treeData.value = trees;
    // 渲染完成之后
    await nextTick();
    // 获取 节点数据 触发 click方式
    // treeRef.value!.store
    // 默认选中第一个
    handleNodeClick(trees[0] || null);
  };
  // 滚动到指定位置
  const handleScrollToPosition = (orgId: string) => {
    const addNodeDom = document.querySelector(`.el-scrollbar__view [data-key="${orgId}"]`);
    if (!addNodeDom) {
      return;
    }
    if (addNodeDom?.scrollIntoView) {
      addNodeDom?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };
  const nodeUpdater = (
    type: ORG_DIALOG_OPERATE_ENUM,
    parent: IOrgTreeItem|null,
    current: IOrgTreeItem,
    oldOrgName: string = '',
  ) => {
    switch (type) {
      case ORG_DIALOG_OPERATE_ENUM.ADD:
        if (!nodeChildPathMap.has(current.orgId)) {
          nodeChildPathMap.set(current.orgId, [current.orgName]);
        }
        if (!parent) break;
        nodeChildPathMap.get(parent.orgId)?.push(current.orgName);
        if (parent.parent) {
          nodeUpdater(type, parent.parent, current, oldOrgName);
        }
        break;
      case ORG_DIALOG_OPERATE_ENUM.DELETE: {
        if (nodeChildPathMap.has(current.orgId)) {
          nodeChildPathMap.delete(current.orgId);
        }
        if (!parent) break;
        const pNodePath = nodeChildPathMap.get(parent.orgId)!;
        const index = pNodePath.findIndex(v => v === oldOrgName);
        pNodePath.splice(index, 1);
        if (parent.parent) {
          nodeUpdater(type, parent.parent, current, oldOrgName);
        }
        break;
      }
      case ORG_DIALOG_OPERATE_ENUM.MODIFY: {
        const currentNodePath = nodeChildPathMap.get(current.orgId)!;
        if (currentNodePath[currentNodePath.length - 1] !== current.orgName) {
          currentNodePath[currentNodePath.length - 1] = current.orgName;
        }
        if (!parent) break;
        const pNodePath = nodeChildPathMap.get(parent.orgId)!;
        const index = pNodePath.findIndex(v => v === oldOrgName);
        pNodePath[index] = current.orgName;
        if (parent.parent) {
          nodeUpdater(type, parent.parent, current, oldOrgName);
        }
        break;
      }
      default:
        break;
    }
  };
  // 删除节点
  const handleDelete = async (deleteOrgData: IOrgTreeItem, node: ITreeNode) => {
    try {
      if (!deleteOrgData.orgId) {
        return;
      }
      await ElMessageBox.confirm(`是否删除【${deleteOrgData.orgName}】部门`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      });
      await deleteOrg({
        orgId: deleteOrgData.orgId,
      });
      // 获取要删除节点的数据， node.level === 1 表示删除根节点
      const deleteTarget = node.level === 1 ? treeData.value : (node?.parent?.data?.children || []);

      const tempIndex = deleteTarget.findIndex((item: IOrgTreeItem) => {
        return item.orgId === deleteOrgData.orgId;
      });
      if (tempIndex !== -1) {
        deleteTarget.splice(tempIndex, 1);
      }
      // 删除当前节点
      if (activeNodeData.value?.orgId === deleteOrgData.orgId) {
        handleNodeClick(treeData.value[0] || null);
      }
      nodeUpdater(ORG_DIALOG_OPERATE_ENUM.DELETE, deleteOrgData.parent, deleteOrgData);
      ElMessage.success('删除成功');
    } catch (e) {
      console.log(e);
    }
  };
  // 展开节点
  const handleNodeExpand = (row: IOrgTreeItem) => {
    expandedKeys.value.push(row.orgId);
  };
  // 收起节点
  const handleNodeCollapse = (row: IOrgTreeItem) => {
    const index = expandedKeys.value.findIndex(v => v === row.orgId);
    if (index !== -1) {
      expandedKeys.value.splice(index, 1);
    }
  };

  const {
    getOrgTreePath,
  } = useOrgTreePath();
  // 获取含关键字的路径
  const getKeywordPaths = (pathList: string[][], keyword: string): string[][] => {
    if (!pathList.length) {
      return [];
    }
    return pathList.filter((item: string[]) => {
      return item.some((str) => {
        return str.indexOf(keyword) !== -1;
      });
    });
  };

  // 过滤节点
  const handleFilterNode = (value: string, data: IOrgTreeItem) => {
    if (!value) {
      return true;
    }
    return nodeChildPathMap.get(data.orgId)?.some(v => v.indexOf(value) !== -1);
  };
  // 初始化数据
  const handleInit = () => {
    getData();
  };
  onMounted(() => {
    handleInit();
  });
  /*
  * 触发el-tree 的filter
  * */
  const filterKeyword = ref<string>('');
  watch(() => filterKeyword.value, (newVal) => {
    treeRef.value?.filter(newVal);
  });
  return {
    filterKeyword,
    treeRef,
    expandedKeys,
    treeData,
    handleFilterNode,
    handleDelete,
    handleNodeClick,
    handleNodeExpand,
    handleNodeCollapse,
    handleScrollToPosition,
    nodeUpdater,
  };
}
