
import { defineComponent, PropType, ref } from 'vue';
import { ElForm } from 'element-plus';
import { addOrg, modifyOrg } from '@/modules/setting-manage/user-manage/api';
import { ORG_DIALOG_OPERATE_ENUM, ORG_DIALOG_LIST } from './type';
import { IAddOrgReq, IGetOrgTreeItem, IModifyOrgReq } from '@/modules/setting-manage/user-manage/api/type';
import { IOrgTreeItem } from '../aside-tree/type';

export default defineComponent({
  emits: ['update:visible', 'on-ok-callback'],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    // 操作类型
    operateType: {
      type: String as PropType<ORG_DIALOG_OPERATE_ENUM>,
      default: '',
      required: true,
    },
    // 组织数据
    orgData: {
      type: Object as PropType<IOrgTreeItem | null>,
      default: () => ({}),
      required: true,
    },
  },
  setup(props, { emit }) {
    const form = ref<{ orgName: string; }>({
      orgName: '',
    });
    const formRef = ref<InstanceType<typeof ElForm> | null>(null);
    const handleClose = async () => {
      await formRef.value!.resetFields();
      emit('update:visible', false);
    };
    // 初始化
    const handleOpen = () => {
      if (props.operateType === ORG_DIALOG_OPERATE_ENUM.MODIFY && props.orgData?.orgId) {
        form.value = {
          orgName: props.orgData.orgName,
        };
      }
    };
    const formRules = {
      orgName: [
        { required: true, message: '请输入部门名称' },
      ],
    };

    // 保存
    const handleConfirm = async () => {
      try {
        let callbackData: IOrgTreeItem;
        // 表单校验
        await formRef.value?.validate();
        // 新增
        if (props.operateType === ORG_DIALOG_OPERATE_ENUM.ADD) {
          const reqParams: IAddOrgReq = {
            parentId: props.orgData!.orgId,
            orgName: form.value.orgName,
          };
          const res = await addOrg(reqParams);
          callbackData = {
            ...res.data,
            children: [],
            parent: props.orgData || null,
          };
        } else {
          // 编辑节点
          const reqParams: IModifyOrgReq = {
            orgId: props.orgData!.orgId,
            orgName: form.value.orgName,
          };
          await modifyOrg(reqParams);
          const obj = props.orgData!;
          obj!.orgName = form.value.orgName;
          callbackData = obj;
        }
        // 确认返回信息之后才能做
        emit('on-ok-callback', callbackData, props.orgData?.orgName);
        handleClose();
      } catch (error) {
        console.log(error);
      }
    };

    return {
      formRef,
      formRules,
      form,
      handleConfirm,
      handleClose,
      handleOpen,

      ORG_DIALOG_LIST,
    };
  },
});
