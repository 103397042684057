
import { defineComponent, SetupContext } from 'vue';
import { useAsideTree } from '@/modules/setting-manage/user-manage/views/list/components/aside-tree/use-aside-tree';
import OrgOperateDialog from '../org-operate-dialog/index.vue';
import useOrgOperateDialog from '../org-operate-dialog/use-org-operate-dialog';
import { useAccountStore } from '@/store/account';
import usePermissionConfig from '@/modules/setting-manage/user-manage/use-permission-config';

export default defineComponent({
  components: {
    OrgOperateDialog,
  },
  emits: [
    'click-node-change',
  ],
  setup(props, ctx: SetupContext) {
    const permissionConfig = usePermissionConfig();
    const { expose } = ctx;
    // 部门树
    const {
      treeRef,
      expandedKeys,
      treeData,
      handleFilterNode,
      handleDelete,
      handleNodeClick,
      handleNodeExpand,
      handleNodeCollapse,
      handleScrollToPosition,
      filterKeyword,
      nodeUpdater,
    } = useAsideTree();

    // 操作弹窗
    const {
      orgOperateDialog,
      orgOperateDialogInstance,
      handleAddRootNode,
      handleAddChildNode,
      handleEditNode,
      handleNodeCallback,
    } = useOrgOperateDialog(treeData, handleScrollToPosition, nodeUpdater);
    expose({
      handleAddRootNode,
    });

    return {
      permissionConfig,
      // 公司名称
      companyName: useAccountStore().currentCompany?.companyName,
      filterKeyword,
      treeRef,
      expandedKeys,
      treeData,
      handleFilterNode,
      handleDelete,
      handleNodeClick,
      handleNodeExpand,
      handleNodeCollapse,

      orgOperateDialog,
      orgOperateDialogInstance,
      handleAddRootNode,
      handleAddChildNode,
      handleEditNode,
      handleNodeCallback,
    };
  },
});
